import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import './tailwind.css';
import { MdSearch } from 'react-icons/md';
import DatePicker from "react-datepicker";
import { Line } from "react-chartjs-2";
import * as XLSX from "xlsx";
import GaugeChart from "react-gauge-chart";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import Papa from "papaparse";
import logo from '../Assets/logo2.png';
import clear_icon from "../Assets/weather/clear.png";
import cloud_icon from "../Assets/weather/cloud.png";
import drizzle_icon from "../Assets/weather/drizzle.png";
import rain_icon from "../Assets/weather/rain.png";
import snow_icon from "../Assets/weather/snow.png";
import mist_icon from "../Assets/weather/fog.png";
import thunderstorm_icon from "../Assets/weather/thunderstorm.png";
import { PiWind } from "react-icons/pi";
import { WiHumidity } from "react-icons/wi";
import { GiSpeedometer } from "react-icons/gi";
import { useState } from 'react';
// import loadingIcon from '../Assets/loading.gif';

const BatteryGauge = ({ id }) => {
  const [selectedVoltage, setSelectedVoltage] = useState(18);

  const [range, setRange] = useState([3, 6, 9, 18]); // Custom range, replace arcsLength with range
  const [isEditOpen, setIsEditOpen] = useState(false); // State to toggle modal visibility
  const [newVoltage, setNewVoltage] = useState(18); // Temporary voltage for modal
  const [newRange, setNewRange] = useState(range); // Temporary range for modal
  const [isLoading, setIsLoading] = useState(true);
  const [batteryVoltage, setBatteryVoltage] = useState(0);

  // Function to handle voltage input change
  const handleVoltageChange = (e) => {
    setSelectedVoltage(Number(e.target.value));
  };

  // Fetch battery data from the API

  // Calculate percentage based on fetched battery voltage
  const handleSubmit = () => {
    setSelectedVoltage(newVoltage);
    setRange(newRange);
    setIsEditOpen(false); // Close the modal after submitting
  };

  return (
    <div
      style={{
        width: "400px",
        height: "350px", // Set a specific height
        padding: "20px",
        border: "2px solid black",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* Display selected voltage */}
      <h3 style={{ fontWeight: "bold" }}>
        Battery Set to: {selectedVoltage}V
      </h3>
      <div style={{ textAlign: "center", width: "470px" }}>
        {!isLoading ? (
          <GaugeChart
            id="battery-gauge"
            nrOfLevels={range.length} // Number of levels based on custom range
            arcsLength={range.map((value) => value / selectedVoltage)} // Dynamic arcs based on user range
            colors={["#FF0000", "#FFA500", "#FFD700", "#008000"]} // Colors for the gauge
            percent={batteryVoltage / selectedVoltage} // Adjust gauge percentage according to selectedVoltage
            arcPadding={0.02}
            textColor={"#000000"}
            needleColor={"#000000"}
            needleBaseColor={"#000000"}
            style={{ height: "250px", width: "450px" }}
            formatTextValue={() => `${batteryVoltage.toFixed(1)}V`} // Display the battery voltage
          />
        ) : (
          <p>Loading battery data...</p>
        )}
      </div>

      {/* Edit Button */}
      <button
        onClick={() => setIsEditOpen(true)}
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          padding: "5px 10px",
          backgroundColor: "#007BFF",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Edit
      </button>

      {/* Modal for voltage and range input */}
      {isEditOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            zIndex: 1000,
            width: "300px", // Decreased width
          }}
        >
          <h4>Edit Voltage and Range</h4>

          {/* Voltage Input */}
          <label>Enter Voltage: </label>
          <input
            className="border rounded-md p-1"
            type="number"
            value={newVoltage}
            onChange={(e) => setNewVoltage(Number(e.target.value))}
            style={{ marginBottom: "10px", width: "100%" }}
          />

          {/* Range Input */}
          <label>Set Range (comma-separated values): </label>
          <input
            className="border rounded-md p-1"
            type="text"
            value={newRange.join(", ")}
            onChange={(e) =>
              setNewRange(e.target.value.split(",").map(Number))
            }
            style={{ marginBottom: "10px", width: "100%" }}
          />

          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            style={{
              padding: "5px 10px",
              backgroundColor: "#28A745",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>

          {/* Cancel Button */}
          <button
            onClick={() => setIsEditOpen(false)}
            style={{
              marginLeft: "10px",
              padding: "5px 10px",
              backgroundColor: "#DC3545",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
        </div>
      )}

      {/* Overlay for modal */}
      {isEditOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 999,
          }}
        ></div>
      )}
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  //   const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [BackEndData, setBackEndData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [bridgeIdToDelete, setBridgeIdToDelete] = useState(null);
  const [bridgename, setbridgename] = useState('');
  const [id, setId] = useState('');
  const [showBridgeDetailsNew, setShowBridgeDetails] = useState(false);
  const [superdata, setsuperdata] = useState([]);
  const superadminId = localStorage.getItem('superadminId')
  const [refresh, setRefresh] = useState(false)
  const chartRef = useRef(null);
  const [humidityData, setHumidityData] = useState([]);
  const [bridgeNameValue, setBridgeName] = useState('');
  const [division, setDivision] = useState('');
  const [location, setLocation] = useState('');
  const [owner, setOwner] = useState('');
  const [adminName, setAdminName] = useState('');
  const [managerName, setManagerName] = useState('');
  const cityRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  const { ID } = useParams();
  console.log('adminid ', id)

  const weatherapi = {
    key: "ce6e8efc9563bba968f6c6284d0253df",
    base: "https://api.openweathermap.org/data/2.5/",
  };


  const initialUserData = {
    country: "",
    state: "",
    city: "",
    coordinates: "",
    division: "",
    location: "",
    bridgeName: "",
    adminInfo: {
      adminEmail: "",
      adminName: "",
      adminPhone: "",
      adminEmail2: "",
      adminName2: "",
      adminPhone2: "",
      adminEmail3: "",
      adminName3: "",
      adminPhone3: "",
      admin1countryCode: "code",
      admin2countryCode: "code",
      admin3countryCode: "code",
    },
    managerInfo: {
      managerEmail: "",
      managerName: "",
      managerPhone: "",
      managerEmail2: "",
      managerName2: "",
      managerPhone2: "",
      managerEmail3: "",
      managerName3: "",
      managerPhone3: "",
      managerEmail4: "",
      managerName4: "",
      managerPhone4: "",
      managerEmail5: "",
      managerName5: "",
      managerPhone5: "",
      managerEmail6: "",
      managerName6: "",
      managerPhone6: "",
      manager1countryCode: "code",
      manager2countryCode: "code",
      manager3countryCode: "code",
      manager4countryCode: "code",
      manager5countryCode: "code",
      manager6countryCode: "code",
    },
    ownerInfo: {
      ownerEmail: "",
      ownerName: "",
      ownerPhone: "",
      ownerEmail2: "",
      ownerName2: "",
      ownerPhone2: "",
      ownerEmail3: "",
      ownerName3: "",
      ownerPhone3: "",
      owner1countryCode: "code",
      owner2countryCode: "code",
      owner3countryCode: "code",
    },
    noofgirders: "",
    nobridgespan: "",
  };
  const [userData, setUserData] = useState(initialUserData)
  const [Weather, setWeather] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const email = localStorage.getItem('email');
        if (!email) {
          navigate('/');
          enqueueSnackbar('Please Login to Navigate!', { variant: 'error' });
          return;
        }
        const response = await axios.get(`https://shmbackend.dext.site/bridge/showbridge?email=${email}`);
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data);
          setBackEndData(response.data);
          setUserData(response.data)
        } else {
          console.error('Failed to fetch data:', response.statusText);
        }

      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, [navigate, enqueueSnackbar, refresh]);

  const RedirectDashboard = (bridgeName, id) => {
    localStorage.setItem('bridgeName', bridgeName);
    navigate(`/home/dashboard/bridge/${id}`);
  };

  const handleRowClick = (bridgeName, id) => {
    RedirectDashboard(bridgeName, id);
  };

  // Filter the data based on searchKeyword
  // const filteredDataBridges = BackEndData.filter(
  //   (data) =>
  //     data.bridge.bridgeName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
  //     data.bridge.country.toLowerCase().includes(searchKeyword.toLowerCase()) ||
  //     data.bridge.state.toLowerCase().includes(searchKeyword.toLowerCase()) ||
  //     data.bridge.division.toLowerCase().includes(searchKeyword.toLowerCase())
  // );

  const filteredDataBridges = BackEndData.map((data) => {
    // Collect non-empty owner names
    const ownerNames = [
      data.bridge.ownerName,
      data.bridge.ownerName2,
      data.bridge.ownerName3,
    ].filter((owner) => owner && owner.trim() !== "");

    // Collect non-empty admin names
    const adminNames = [
      data.bridge.adminName,
      data.bridge.adminName2,
      data.bridge.adminName3,
    ].filter((admin) => admin && admin.trim() !== "");

    // Collect non-empty manager names
    const managerNames = [
      data.bridge.managerName,
      data.bridge.managerName2,
      data.bridge.managerName3,
      data.bridge.managerName4,
      data.bridge.managerName5,
      data.bridge.managerName6,
    ].filter((manager) => manager && manager.trim() !== "");

    // Return the transformed object
    return {
      bridgeName: data.bridge.bridgeName,
      division: data.bridge.division,
      city: data.bridge.city,
      ownerNames,
      adminNames,
      managerNames,
    };
  });

  // Filter based on searchKeyword
  const searchResults = filteredDataBridges.filter((item) => {
    return (
      item.bridgeName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.division.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.city.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.ownerNames.some((owner) => owner.toLowerCase().includes(searchKeyword.toLowerCase())) ||
      item.adminNames.some((admin) => admin.toLowerCase().includes(searchKeyword.toLowerCase())) ||
      item.managerNames.some((manager) => manager.toLowerCase().includes(searchKeyword.toLowerCase()))
    );
  });

  console.log(searchResults);


  console.log(filteredDataBridges)


  const [enabledSelects, setEnabledSelects] = useState([true, false, false, false, false, false]);
  const [sensortype2, setsensortype2] = useState("");
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [selectedSensor, setSelectedSensor] = useState("");
  const [sensorTypeOptions, setSensorTypeOptions] = useState([]);
  const [sensorLocation2, setsensorLocation2] = useState("");
  const [newValue, setnewValue] = useState("");
  const [showSensorDetails, setshowSensorDetails] = useState(false);
  const [showaccelerometers, setshowaccelerometers] = useState(false);
  const [showstraingauges, setshowstraingauges] = useState(false);
  const [showUser, setshowUser] = useState(false);
  const [showDashboard, setshowDashboard] = useState(true);
  const [showSensorDashboard, setshowSensorDashboard] = useState(false);
  const [showModify, setshowModify] = useState(false);
  const [showBridgeDetails, setshowBridgeDetails] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [uploadMessage, setUploadMessage] = useState("");
  const [selectedAxis, setSelectedAxis] = useState("all");
  const [temperatureData, setTemperatureData] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [selectedPressureAxis, setSelectedPressureAxis] = useState("x");
  const [pressureData, setPressureData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [xMin, setXMin] = useState(0);
  const [xMax, setXMax] = useState(10);
  const [tiltData, setTiltData] = useState([]);
  const maxDataPoints = 60;
  const [filteredData, setFilteredData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [accelData, setAccelData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [batteryData, setBatteryData] = useState([]);
  const [batteryVoltage, setBatteryVoltage] = useState(0);

  const handleSensorChangecombine = (e) => {
    setSelectedSensor(e.target.value);
    setnewValue(e.target.value);

    setshowaccelerometers(true);
    setshowstraingauges(false);
    setshowDashboard(true);
    setshowSensorDashboard(false);
    setshowModify(false);
    setshowSensorDetails(false);
    setshowBridgeDetails(false);
    setshowUser(false);
  };

  const liveChartData = {
    labels: liveData.map((entry) =>
      entry.timestamp.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    ), // Directly use the timestamp as is
    datasets:
      selectedAxis === "all"
        ? [
          {
            label: "X-Axis",
            data: liveData.map((entry) => entry.x),
            borderColor: "red",
            fill: false,
          },
          {
            label: "Y-Axis",
            data: liveData.map((entry) => entry.y),
            borderColor: "green",
            fill: false,
          },
          {
            label: "Z-Axis",
            data: liveData.map((entry) => entry.z),
            borderColor: "blue",
            fill: false,
          },
        ]
        : selectedAxis === "x"
          ? [
            {
              label: "X-Axis",
              // data: liveData.map((entry) => entry.x),
              data: liveData.map((entry) => entry.x),
              borderColor: "red",
              fill: false,
            },
          ]
          : selectedAxis === "y"
            ? [
              {
                label: "Y-Axis",
                data: liveData.map((entry) => entry.y),
                borderColor: "green",
                fill: false,
              },
            ]
            : [
              {
                label: "Z-Axis",
                data: liveData.map((entry) => entry.z),
                borderColor: "blue",
                fill: false,
              },
            ],
  };

  const loadOlderData = () => {
    setLiveData((prevData) => {
      const additionalData = accelData.slice(
        Math.max(0, prevData.length - 60 - maxDataPoints),
        prevData.length - maxDataPoints
      );
      return [...additionalData, ...prevData];
    });
  };

  const humidityGraphData = {
    labels: humidityData.map((row) => row[""]), // X-axis as timestamp
    datasets: [
      // Add dataset configuration here
    ],
  };

  const humidityOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "",
        },
      },
      y: {
        title: {
          display: true,
          text: "",
        },
      },
    },
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label || ""}: ${context.parsed.y}`,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x", // Enable horizontal panning
          threshold: 10,
          onPan: ({ chart }) => {
            const { min, max } = chart.scales.x;
            if (min <= chart.data.labels[0]) {
              loadOlderData(); // Load older data if needed when panning
            }
          },
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          drag: {
            enabled: false,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        // No need to specify type if it's already properly registered

        title: { display: true, text: "Time" },
        ticks: {
          autoSkip: true,
          maxTicks: 10,
          padding: 0,
        },

        min: xMin,
        max: xMax,
      },

      y: {
        title: { display: true, text: "Value" },
      },
    },
  };

  const pressureChartData = {
    labels: pressureData.map((row) =>
      row.timestamp.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    ), // X-axis will display time
    datasets: [
      {
        label: "Pressure",
        data: pressureData.map((row) => row.pressure),
        borderColor: "rgba(255, 99, 132, 1)", // Line color
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const pressureChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label || ""}: ${context.parsed.y}`,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x", // Enable horizontal panning
          threshold: 10,
          onPan: ({ chart }) => {
            const { min, max } = chart.scales.x;
            if (min <= chart.data.labels[0]) {
              loadOlderData(); // Load older data if needed when panning
            }
          },
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          drag: {
            enabled: false,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        // No need to specify type if it's already properly registered

        title: { display: true, text: "Time" },
        ticks: {
          autoSkip: true,
          maxTicks: 10,
          padding: 0,
        },

        min: 0,
        max: 10,
      },

      y: {
        title: { display: true, text: "Value" },
      },
    },
  };

  const temperatureChartData = {
    labels: temperatureData.map((row) => row.timestamp.toLocaleTimeString()), // X-axis will display time
    datasets: [
      {
        label: "Temperature",
        data: temperatureData.map((row) => row.temperature),
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        fill: false,
        tension: 0.1,
      },
    ],
  };

  // Chart options to add axis labels
  const temperatureChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label || ""}: ${context.parsed.y}`,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x", // Enable horizontal panning
          threshold: 10,
          onPan: ({ chart }) => {
            const { min, max } = chart.scales.x;
            if (min <= chart.data.labels[0]) {
              loadOlderData(); // Load older data if needed when panning
            }
          },
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          drag: {
            enabled: false,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time", // X-axis label
        },
        ticks: {
          autoSkip: true,
          maxTicks: 10,
          padding: 0,
        },
        min: 0, // Start from the first point
        max: 10, // Display up to the 10th point (index 0 to 9)
      },
      y: {
        title: {
          display: true,
          text: "", // Y-axis label
        },
      },
    },
  };

  const handleDownloadPressure = (format) => {
    if (format == "pdf") {
      handleDownloadPDFPressure();
    } else if (format == "excel") {
      handleDownloadExcelPressure();
    } else if (format == "word") {
      handleDownloadWordPressure();
    }
  };

  const handleSelectChange = (index, value, target) => {
    const updatedEnabledSelects = [...enabledSelects];

    switch (target) {
      case 'bridge':
        setBridgeName(value);
        break;

      case 'division':
        setDivision(value);
        break;

      case 'location':
        setLocation(value);
        break;

      case 'owner':
        setOwner(value);
        break;

      case 'admin':
        setAdminName(value);
        break;

      case 'manager':
        setManagerName(value);
        break;
    }

    if (index === 0) {
      // If "Location" is selected, enable all remaining fields
      for (let i = index + 1; i < updatedEnabledSelects.length; i++) {
        updatedEnabledSelects[i] = true;
      }
    } else if (index < enabledSelects.length - 1) {
      // Enable the next field for other cases
      updatedEnabledSelects[index + 1] = true;
    }

    setEnabledSelects(updatedEnabledSelects);
  };

  const handleSensorTypeChange2 = (e) => {
    setsensortype2(e.target.value);
    //setSelectedSensor(""); // Reset the sensor selection when sensor type changes
    setsensorLocation2(""); // Reset the sensor location as well
    setSelectedSensor('')
  };



  const [statesList, setStatesList] = useState([]);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setUserData(prevData => ({ ...prevData, country: selectedCountry }));

    // Fetch states based on selected country
    if (selectedCountry === 'USA') {
      setStatesList(['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']);
    } else if (selectedCountry === 'India') {
      setStatesList(['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal']);
    } else {
      setStatesList([]);
    }

    // Reset state selection
    setUserData(prevData => ({ ...prevData, state: '' }));
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setUserData(prevData => ({ ...prevData, state: selectedState }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Hello Void')
        if (!id) {
          return;
        }
        const response = await axios.get(`https://shmbackend.dext.site/bridge/getbridge/${id}`);
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data);
          const { country, state, city, coordinates, division, zipCode, bridgeName,
            adminName, adminEmail, adminPhone, adminName2, adminEmail2, adminPhone2,
            adminName3, adminEmail3, adminPhone3, managerName, managerEmail, managerPhone,
            managerName2, managerEmail2, managerPhone2, managerName3, managerEmail3, managerPhone3,
            managerName4, managerEmail4, managerPhone4, managerName5, managerEmail5, managerPhone5,
            managerName6, managerEmail6, managerPhone6, ownerName, ownerEmail, ownerPhone, ownerName2,
            ownerEmail2, ownerPhone2, ownerName3, ownerEmail3, ownerPhone3, nobridgespan, noofgirders,
            admin1countryCode, admin2countryCode, admin3countryCode, owner1countryCode, owner2countryCode,
            owner3countryCode, manager1countryCode, manager2countryCode, manager3countryCode, manager4countryCode,
            manager5countryCode, manager6countryCode } = response.data;

          console.log(response)

          setUserData({
            country, state, city, coordinates, division, zipCode, bridgeName,
            adminName, adminEmail, adminPhone, adminName2, adminEmail2, adminPhone2,
            adminName3, adminEmail3, adminPhone3, managerName, managerEmail, managerPhone,
            managerName2, managerEmail2, managerPhone2, managerName3, managerEmail3, managerPhone3,
            managerName4, managerEmail4, managerPhone4, managerName5, managerEmail5, managerPhone5,
            managerName6, managerEmail6, managerPhone6, ownerName, ownerEmail, ownerPhone, ownerName2,
            ownerEmail2, ownerPhone2, ownerName3, ownerEmail3, ownerPhone3, nobridgespan, noofgirders,
            admin1countryCode, admin2countryCode, admin3countryCode, owner1countryCode, owner2countryCode,
            owner3countryCode, manager1countryCode, manager2countryCode, manager3countryCode, manager4countryCode,
            manager5countryCode, manager6countryCode
          });
        } else {
          console.error('Failed to fetch data:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id]);

  console.log(userData)

  const updateData = async (dataToUpdate) => {
    if (!userData.adminName || !userData.adminEmail || !userData.adminPhone || userData.admin1countryCode === 'code') {
      enqueueSnackbar('Please Add Atleast One Admin!', { variant: 'error' });
    }
    else if (!userData.managerName || !userData.managerEmail || !userData.managerPhone || userData.manager1countryCode === 'code') {
      enqueueSnackbar('Please Add Atleast One Manager!', { variant: 'error' });
    }
    else if (!userData.ownerName || !userData.ownerEmail || !userData.ownerPhone || userData.owner1countryCode === 'code') {
      enqueueSnackbar('Please Add Atleast One Owner!', { variant: 'error' });
    }
    else {
      try {
        const dataToUpdate = {
          country: userData.country,
          state: userData.state,
          city: userData.city,
          coordinates: userData.coordinates,
          division: userData.division,
          zipCode: userData.zipCode,
          bridgeName: userData.bridgeName,

          adminEmail: userData.adminEmail,
          adminName: userData.adminName,
          adminPhone: userData.adminPhone,
          managerEmail: userData.managerEmail,
          managerName: userData.managerName,
          managerPhone: userData.managerPhone,
          ownerEmail: userData.ownerEmail,
          ownerName: userData.ownerName,
          ownerPhone: userData.ownerPhone,

          adminEmail2: userData.adminEmail2,
          adminName2: userData.adminName2,
          adminPhone2: userData.adminPhone2,
          managerEmail2: userData.managerEmail2,
          managerName2: userData.managerName2,
          managerPhone2: userData.managerPhone2,
          ownerEmail2: userData.ownerEmail2,
          ownerName2: userData.ownerName2,
          ownerPhone2: userData.ownerPhone2,

          adminEmail3: userData.adminEmail3,
          adminName3: userData.adminName3,
          adminPhone3: userData.adminPhone3,
          managerEmail3: userData.managerEmail3,
          managerName3: userData.managerName3,
          managerPhone3: userData.managerPhone3,
          ownerEmail3: userData.ownerEmail3,
          ownerName3: userData.ownerName3,
          ownerPhone3: userData.ownerPhone3,

          managerEmail4: userData.managerEmail4,
          managerName4: userData.managerName4,
          managerPhone4: userData.managerPhone4,

          managerEmail5: userData.managerEmail5,
          managerName5: userData.managerName5,
          managerPhone5: userData.managerPhone5,

          managerEmail6: userData.managerEmail6,
          managerName6: userData.managerName6,
          managerPhone6: userData.managerPhone6,

          admin1countryCode: userData.admin1countryCode,
          admin2countryCode: userData.admin2countryCode,
          admin3countryCode: userData.admin3countryCode,

          owner1countryCode: userData.owner1countryCode,
          owner2countryCode: userData.owner2countryCode,
          owner3countryCode: userData.owner3countryCode,

          manager1countryCode: userData.manager1countryCode,
          manager2countryCode: userData.manager2countryCode,
          manager3countryCode: userData.manager3countryCode,
          manager4countryCode: userData.manager4countryCode,
          manager5countryCode: userData.manager5countryCode,
          manager6countryCode: userData.manager6countryCode,
        };
        const response = await axios.put(`https://shmbackend.dext.site/bridge/updatebridge/${id}`, dataToUpdate);
        if (response.status >= 200 && response.status < 300) {
          // alert('Data Updated Successfully!');
          enqueueSnackbar("Details Updated Successfully", { variant: "success" });
          // console.log('Data updated successfully:', response.data);
          setShowBridgeDetails(false);
          setRefresh(!refresh)
          // const updatedResponse = await axios.get(`https://shmbackend.dext.site/bridge/superbridges?superadminId=${superadminId}`);
          // setsuperdata(updatedResponse.data);
          return response.data;
        }
      }
      catch (error) {
        console.error('Error updating data:', error);
        enqueueSnackbar("Details Failed to Update", { variant: "error" });
        throw error;
      }
    }
  };

  const handleDeleteClick = async (bridgename1) => {
    try {
      // Fetch the bridge id based on the name
      const response = await axios.get(`https://shmbackend.dext.site/bridge/bridgeid?bridgeName=${bridgename1}`);

      if (response.status >= 200 && response.status < 300) {
        const id = response.data;
        // Show the confirmation dialog
        setShowDialog(true);

        // Store the bridge id for later use in handleConfirm
        setBridgeIdToDelete(id);
      } else {
        console.error('Failed to fetch bridge id:', response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);

      enqueueSnackbar("An error occurred while fetching the bridge id.", { variant: 'error' });

    }
  };

  const tiltGraphData = {
    labels: tiltData.map((row) => row.timestamp), // X-axis as timestamp
    datasets: [],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "",
        },
        ticks: {
          callback: function (value) {
            return value; // Ensure timestamp labels are displayed correctly
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "",
        },
      },
    },
  };

  const handleConfirm = async () => {
    try {
      // Proceed with deletion after confirmation
      const deleteResponse = await fetch(`https://shmbackend.dext.site/bridge/deletebridge/${bridgeIdToDelete}`, {
        method: 'DELETE',
      });

      if (!deleteResponse.ok) {
        const error = await deleteResponse.json();
        enqueueSnackbar(`Failed to delete bridge: ${error.message}`, { variant: 'error' });
      } else {
        enqueueSnackbar('Bridge Deleted Successfully', { variant: 'success' });
        // Optionally, you can refresh the list or update the state here
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting bridge:", error);
      alert();
      enqueueSnackbar("An error occurred while deleting the bridge.", { variant: 'error' });
    } finally {
      // Close the dialog after action
      setShowDialog(false);
    }
  };
  const ModifyAdmin = (bn, id) => {
    setbridgename(bn);
    setId(id);
    console.log(bridgename);
    setShowBridgeDetails(true);
  };

  const handleDownloadPDFPressure = () => {
    const dataToDownloadPressure = pressureData.length ? pressureData : [];
    const docPressure = new jsPDF();
    docPressure.text("Pressure Monitoring", 10, 10);
    const tablePressure = dataToDownloadPressure.map((entry) => [
      entry.timestamp.toLocaleString(),
      entry.pressure,
    ]);
    docPressure.autoTable({
      head: ["Timestamp", "Pressure"],
      body: tablePressure,
    });
    docPressure.save("pressureData.pdf");
  };

  const handleDownloadExcelPressure = () => {
    const dataToDownloadPressure = pressureData.length ? pressureData : [];
    const worksheetPressure = XLSX.utils.json_to_sheet(dataToDownloadPressure);
    const workbookPressure = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbookPressure,
      worksheetPressure,
      "Pressure"
    );
    const excelBufferPressure = XLSX.write(workbookPressure, {
      bookType: "xlsx",
      type: "array",
    });
    const blobPressure = new Blob([excelBufferPressure], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blobPressure, "pressureData.xlsx");
  };

  const handleDownloadPDFTemperature = () => {
    const dataToDownloadTemperature =
      temperatureChartData.length > 0 ? temperatureChartData : csvData;
    const docTemperature = new jsPDF();
    docTemperature.text("Temperature Data", 10, 10);
    const tableDataTemperature = dataToDownloadTemperature.map((row) =>
      Object.values(row)
    );
    docTemperature.table(
      10,
      20,
      tableDataTemperature,
      Object.keys(dataToDownloadTemperature[0])
    );
    docTemperature.save("Temperature.pdf");
  };

  const handleDownloadExcelTemperature = () => {
    const dataToDownloadTemperature =
      temperatureChartData.length > 0 ? temperatureChartData : csvData;
    const worksheetTemperature = XLSX.utils.json_to_sheet(
      dataToDownloadTemperature
    );
    const workbookTemperature = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbookTemperature,
      worksheetTemperature,
      "Temperature"
    );
    const excelBufferTemperature = XLSX.write(workbookTemperature, {
      bookType: "xlsx",
      type: "array",
    });
    const dataTemperature = new Blob([excelBufferTemperature], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(dataTemperature, "Temperature.xlsx");
  };

  const handleDownloadWordTemperature = () => {
    const handleDownloadTemperature =
      temperatureChartData.length > 0 ? temperatureChartData : csvData;
    const contentTemperature = handleDownloadTemperature
      .map((row) => Object.values(row))
      .join("\n");
    const blobTemperature = new Blob([contentTemperature], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blobTemperature, "Temperature.txt");
  };

  const handleDownloadWordPressure = () => {
    const dataToDownloadPressure = pressureData.length ? pressureData : [];
    const contentPressure = dataToDownloadPressure
      .map((row) => Object.values(row))
      .join("\n");
    const blobPressure = new Blob([contentPressure], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blobPressure, "pressureData.txt");
  };

  const handleCancel = () => {
    // Just close the dialog without doing anything
    setShowDialog(false);
  };

  const [dataSource, setDataSource] = useState("api");
  const [selectedTiltAxis, setSelectedTiltAxis] = useState("x");


  const handleDownloadTilt = (format) => {
    if (format === "pdf") {
      handleDownloadPDFTilt();
    } else if (format === "excel") {
      handleDownloadExcelTilemeter();
    } else if (format === "word") {
      handleDownloadWordTilt();
    }
  };

  const handleDownloadExcelTilemeter = () => {
    const dataToDownloadTilt = tiltGraphData.length > 0 ? tiltData : [];
    const worksheetTilt = XLSX.utils.json_to_sheet(dataToDownloadTilt);
    const workbookTilt = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookTilt, worksheetTilt, "Tilt Data");
    const excelBufferTilt = XLSX.write(workbookTilt, {
      bookType: "xlsx",
      type: "array",
    });
    const dataTilt = new Blob([excelBufferTilt], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(dataTilt, "tiltData.xlsx");
  };

  const handleDownloadWordTilt = () => {
    const dataToDownloadTilt = tiltGraphData.length > 0 ? tiltData : [];
    const contentTilt = dataToDownloadTilt
      .map((entry) => [entry.timestamp, entry.x, entry.y, entry.z])
      .join("\n");
    const blobTilt = new Blob([contentTilt], { type: "application/msword" });
    saveAs(blobTilt, "tiltData.doc");
  };

  const handleDownloadPDFTilt = () => {
    const dataToDownloadTilt = tiltGraphData.length > 0 ? tiltData : [];
    const docTilt = new jsPDF();
    docTilt.text("Tilt Data", 10, 10);
    const tableDataTilt = dataToDownloadTilt.map((entry) => [
      entry.timestamp,
      entry.x,
      entry.y,
      entry.z,
    ]);
    docTilt.autoTable({
      head: ["Timestamp", "X", "Y", "Z"],
      body: tableDataTilt,
    });
    docTilt.save("tiltData.pdf");
  };

  const handleDownload = (format) => {
    if (format === "excel") {
      handleDownloadExcel();
    } else if (format === "pdf") {
      handleDownloadPDF();
    } else if (format === "word") {
      handleDownloadWord();
    }
  };

  const handleDataSourceChange = (value) => {
    setDataSource(value);
  };

  const filterDataByDateRange = (start, end) => {
    console.log(accelData);
    const filtered = accelData.filter(
      (entry) => entry.timestamp >= start && entry.timestamp <= end
    );
    console.log("Filtered Data:", filtered);
    setFilteredData(filtered);
    console.log(filtered); // Logging first 60 entries
    setLiveData(filtered);
  };

  const handleDownloadExcel = () => {
    const dataToDownload = filteredDataBridges.length ? filteredDataBridges : accelData;
    const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accelerometer Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "accelerometer_data.xlsx");
  };

  const handleDownloadPDF = () => {
    const dataToDownload = filteredDataBridges.length ? filteredDataBridges : accelData;
    const doc = new jsPDF();
    doc.text("Accelerometer Data", 10, 10);
    const tableData = dataToDownload.map((entry) => [
      entry.timestamp.toLocaleString(),
      entry.x,
      entry.y,
      entry.z,
    ]);
    doc.autoTable({
      head: [["Timestamp", "X", "Y", "Z"]],
      body: tableData,
    });
    doc.save("accelerometer_data.pdf");
  };

  const handleDownloadWord = () => {
    const dataToDownload = filteredDataBridges.length ? filteredDataBridges : accelData;
    const content = dataToDownload
      .map((entry) => {
        return `Timestamp: ${entry.timestamp.toLocaleString()}, X: ${entry.x
          }, Y: ${entry.y}, Z: ${entry.z}\n`;
      })
      .join("\n");

    const blob = new Blob([content], { type: "application/msword" });
    saveAs(blob, "accelerometer_data.doc");
  };

  const handleCSVUpload = (file) => {
    setSelectedFileName(file.name); // Set the file name in the state

    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;
      const parsedData = Papa.parse(csvData, {
        header: true, // Assuming the CSV has headers
        dynamicTyping: true, // Auto-convert to appropriate data types
      });

      if (parsedData.errors.length) {
        console.error("Error parsing CSV: ", parsedData.errors);
        setUploadMessage("Error uploading file.");
        return;
      }

      // Only take the first 300 values
      const limitedData = parsedData.data.slice(0, 300).map((entry) => ({
        timestamp: new Date(`${entry.date} ${entry.time}`), // Assuming the date and time are separate columns
        x: entry["Accel X(g)"],
        y: entry["Accel Y(g)"],
        z: entry["Accel Z(g)"],
      }));

      setAccelData(limitedData);
      setFilteredData(limitedData);
      setUploadMessage("File has uploaded successfully");
    };

    reader.readAsText(file);
  };

  const handleDownloadTemperature = (format) => {
    if (format == "pdf") {
      handleDownloadPDFTemperature();
    } else if (format == "excel") {
      handleDownloadExcelTemperature();
    } else if (format == "word") {
      handleDownloadWordTemperature();
    }
  };

  console.log('Initial Renderrrrrrrrr', location)

  useEffect(() => {
    // Fetch weather data for 'Pune' when component mounts
    fetch(
      `${weatherapi.base}weather?q=${'pune'}&units=metric&APPID=${weatherapi.key}`
    )
      .then((res) => res.json())
      .then((result) => {
        setWeather(result);
      })

      .catch((error) => {
        console.error("Error fetching weather data:", error);
      });
  }, [weatherapi.base, weatherapi.key, 'pune', refresh]);


  useEffect(() => {
    const findBridgeID = async () => {
      try {
        const response = await axios.get(`https://shmbackend.dext.site/bridge/bridgeid?bridgeName=${bridgeNameValue}`)
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data)
          setId(response.data)
        } else {
          console.error('Failed to fetch data:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }

    };

    findBridgeID();
  }, [bridgeNameValue]);


  useEffect(() => {
    const fetchSensorType = async () => {
      console.log('iddddddddddddddddd', id);
      try {
        const response = await axios.get(
          `https://shmbackend.dext.site/bridge/unique-sensors?sensortype=${encodeURIComponent(
            sensortype2
          )}&bridgeid=${id}`
        );

        console.log("text", response);

        if (response.status >= 200 && response.status < 300) {
          if (response.data && response.data.length > 0) {
            setSensorTypeOptions(response.data); // Store fetched data for dropdown 2
            setIsDataAvailable(true); // Data is available
          } else {
            setIsDataAvailable(false); // Data is not available
          }
        } else {
          console.error(
            `Failed to fetch sensor types: ${response.status} ${response.statusText}`
          );
          setIsDataAvailable(false);
        }
      } catch (error) {
        console.error("Error fetching sensor types:", error);
        setIsDataAvailable(false);
      }
    };

    // Fetch sensor options only when sensortype2 and id are available
    if (sensortype2 && id) {
      fetchSensorType();
    }
  }, [sensortype2, id, refresh]); // Dependency array includes sensortype2 and id

  // Fetch sensor location based on the selected sensor from dropdown 2
  useEffect(() => {
    const fetchSensorLocation = async () => {
      if (selectedSensor) {
        try {
          const response = await axios.get(
            `https://shmbackend.dext.site/bridge/manual-location?bridgeSensorSrno=${selectedSensor}&bridgeid=${id}`
          );
          if (response.status >= 200 && response.status < 300) {
            setsensorLocation2(response.data); // Update the sensor location input field
          } else {
            console.error(
              `Failed to fetch sensor location: ${response.status} ${response.statusText}`
            );
          }
        } catch (error) {
          console.error("Error fetching sensor location:", error);
        }
      }
    };

    // Fetch sensor location only if a sensor is selected
    if (selectedSensor && id) {
      fetchSensorLocation();
    }
  }, [selectedSensor, id, refresh]); // Dependency array includes selectedSensor and id

  useEffect(() => {
    const fetchData = async () => {
      try {
        {
          /*if (!selectedSensor) {
                    console.warn("Selected Sensor is missing. Cannot fetch data.");
                    return;
                }*/
        }

        if (dataSource === "api") {
          // Only fetch from API if 'api' is selected selectedSensor
          //const ids = "sensor_1";
          console.log(selectedSensor);

          const response = await axios.get(
            `https://shmbackend.dext.site/api/sensor-data/sensor-number/sensor_1`
          );

          if (response.status >= 200 && response.status < 300) {
            // Extract accelerometer data
            console.log(response);
            const sensorDataList = response.data.map((entry) => ({
              timestamp: new Date(entry.timestamp),
              x: entry.accelerometerX,
              y: entry.accelerometerY,
              z: entry.accelerometerZ,
            }));
            console.log("Sensor Data List:", sensorDataList);
            setLiveData((prevData) => {
              const updatedData = [...prevData, ...sensorDataList];

              return updatedData.slice(-maxDataPoints); // Only show the last 60 points initially
            });

            // Store the full data set for panning purposes
            setAccelData(sensorDataList);

            // Extract temperature data
            const tempData = response.data.map((entry) => ({
              timestamp: new Date(entry.timestamp),
              temperature: entry.temperature, // Assuming 'temperature' field is present in the API response
            }));
            console.log("Temperature Data List:", tempData);
            setTemperatureData(tempData);

            //Extract Pressure Data
            const pressureDataList = response.data.map((entry) => ({
              timestamp: new Date(entry.timestamp),
              pressure: entry.pressure, // Assuming 'pressure' field is present in the API response
            }));
            console.log("Pressure Data List:", pressureDataList);
            setPressureData(pressureDataList);

            //Extract Battery Data
            const batteryDataList = response.data.map((entry) => ({
              battery: entry.batteryVoltage,
            }));
            console.log("Battery Data List:", batteryDataList);
            setBatteryData(batteryDataList);
            const latestBatteryVoltage = batteryDataList.slice(-1)[0].battery;
            setBatteryVoltage(latestBatteryVoltage);
            filterDataByDateRange(new Date(), new Date());
          } else {
            console.error(
              `Failed to fetch data: ${response.status} ${response.statusText}`
            );
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    // const interval = setInterval(fetchData, 120000);

    // Cleanup interval on component unmount
    // return () => clearInterval(interval);
    fetchData();
  }, [id, dataSource, selectedSensor, refresh]);


  return (
    <>
      <div className="w-full">
        <div className='flex py-5 px-6 bg-gray-200 border border-gray-300 shadow-2xl'>
          <div className="w-full justify-left">
            <img src={logo} alt="" />
          </div>
          <div className='flex w-full justify-center'>
            <h1 className='font-bold text-3xl'>Added Bridges</h1>
          </div>
          <div className="flex w-full justify-end items-center">
            <MdSearch size={36} className='text-gray-600' />
            <input type="text" placeholder="Search" className="border bg-gray-200 w-56 border-gray-600 rounded-md p-2 mr-2 focus:outline-none" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
          </div>
        </div>
        <br />
        <div className="mt-4 bg-gray-100 mx-5 border border-gray-200 rounded-sm shadow-2xl">
          {/* <table className="table-auto w-full border-collapse border">
            <thead>
              <tr>
                <th className="border bg-black text-lg text-white px-2 py-4 font-bold">#</th>
                <th className="border bg-black text-lg text-white px-16 py-4 font-bold">Bridge Name</th>
                <th className="border bg-black text-lg text-white px-8 py-4 font-bold">Country</th>
                <th className="border bg-black text-lg text-white px-10 py-4 font-bold">State</th>
                <th className="border bg-black text-lg text-white px-10 py-4 font-bold">City</th>
                <th className="border bg-black text-lg text-white px-8 py-4 font-bold">Division</th>
                <th className="border bg-black text-lg text-white px-8 py-4 font-bold">Zip Code</th>

                <th className="border bg-black text-lg text-white px-2 py-4 font-bold">Edit</th>
                <th className="border bg-black text-lg text-white px-2 py-4 font-bold">Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredDataBridges.length > 0 ? (
                filteredDataBridges.map((data, index) => (
                    <tr key={index}  className="hover:bg-gray-300 text-center cursor-pointer border border-gray-300">
                      <td className="border px-2 py-3">{index + 1 }</td>
                      <td className="border px-16 py-3" onClick={() => handleRowClick(data.bridge.bridgeName,data.bridge.id)}>{data.bridge.bridgeName}</td>
                      <td className="border px-8 py-3">{data.bridge.country}</td>
                      <td className="border px-10 py-3">{data.bridge.state}</td>
                      <td className="border px-10 py-3">{data.bridge.city}</td>
                      <td className="border px-8 py-3">{data.bridge.division}</td>
                      <td className="border px-8 py-3">{data.bridge.zipCode}</td>
                      
                      <td className='px-4 py-1'><button onClick={() => ModifyAdmin(data?.bridge?.bridgeName, data?.bridge?.id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >  Edit </button></td>
                    
                    <td className="py-1 px-4 flex justify-center space-x-2">
                      <button onClick={() => handleDeleteClick(data?.bridge?.bridgeName)}
                        //onClick={handleDeleteClick(bridge.bridgeName)}
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
                      >  Delete </button>
                      {showDialog && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                          <div className="bg-white p-6 rounded shadow-lg">
                            <p className="mb-4">Do you want to delete this bridge?</p>
                            <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={handleConfirm}> Yes </button>
                            <button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={handleCancel} >Cancel </button>
                          </div>
                        </div>
                      )}
                    </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="8" className="py-3 text-center text-lg hover:bg-gray-200 cursor-pointer">No bridges found</td>
                </tr>
              )}
            </tbody>
          </table> */}

          <div className="p-5">
            <div className="flex flex-wrap justify-between gap-4">
              <select
                onChange={(e) => handleSelectChange(0, e.target.value, 'bridge')}
                disabled={!enabledSelects[0]}
                value={bridgeNameValue}
                className={`p-2 w-full sm:w-[18%] border rounded shadow-sm
            ${enabledSelects[0] ? "bg-white cursor-pointer" : "bg-gray-200 cursor-not-allowed"}`}
              >
                <option value="" selected disabled>Select Bridge</option>
                {
                  BackEndData.map(data => (
                    <option key={data.bridge.bridgeName}>{data.bridge.bridgeName}</option>
                  ))
                }
              </select>

              <select
                onChange={(e) => handleSelectChange(1, e.target.value, 'division')}
                disabled={!enabledSelects[1]}
                value={division}
                className={`p-2 w-full sm:w-[18%] border rounded shadow-sm 
            ${enabledSelects[1] ? "bg-white cursor-pointer" : "bg-gray-200 cursor-not-allowed"}`}
              >
                {!bridgeNameValue && <option value="">Division</option>}
                <option>
                  {bridgeNameValue && filteredDataBridges.find(item => item.bridgeName === bridgeNameValue).division}
                </option>
              </select>

              <select
                onChange={(e) => handleSelectChange(2, e.target.value, 'location')}
                disabled={!enabledSelects[2]}
                ref={cityRef}
                value={location}
                className={`p-2 w-full sm:w-[18%] border rounded shadow-sm 
            ${enabledSelects[2] ? "bg-white cursor-pointer" : "bg-gray-200 cursor-not-allowed"}`}
              >
                {!bridgeNameValue && <option value="">City</option>}
                <option>
                  {bridgeNameValue && filteredDataBridges.find(item => item.bridgeName === bridgeNameValue).city}
                </option>
              </select>

              <select
                onChange={(e) => handleSelectChange(3, e.target.value, 'owner')}
                disabled={!enabledSelects[3]}
                value={owner}
                className={`p-2 w-full sm:w-[18%] border rounded shadow-sm 
            ${enabledSelects[3] ? "bg-white cursor-pointer" : "bg-gray-200 cursor-not-allowed"}`}
              >
                <option value="">Select Owner</option>
                {bridgeNameValue && filteredDataBridges.find(item => item.bridgeName === bridgeNameValue).ownerNames.map(value => (
                  <option key={value}>{value}</option>
                ))}
              </select>

              <select
                onChange={(e) => handleSelectChange(4, e.target.value, 'admin')}
                disabled={!enabledSelects[4]}
                value={adminName}
                className={`p-2 w-full sm:w-[18%] border rounded shadow-sm 
            ${enabledSelects[4] ? "bg-white cursor-pointer" : "bg-gray-200 cursor-not-allowed"}`}
              >
                <option value="">Select Administrator</option>
                {bridgeNameValue && filteredDataBridges.find(item => item.bridgeName === bridgeNameValue).adminNames.map(value => (
                  <option key={value}>{value}</option>
                ))}
              </select>

              <select
                onChange={(e) => handleSelectChange(5, e.target.value, 'manager')}
                disabled={!enabledSelects[5]}
                value={managerName}
                className={`p-2 w-full sm:w-[18%] border rounded shadow-sm 
            ${enabledSelects[5] ? "bg-white cursor-pointer" : "bg-gray-200 cursor-not-allowed"}`}
              >
                <option value=""> Select Manager</option>
                {bridgeNameValue && filteredDataBridges.find(item => item.bridgeName === bridgeNameValue).managerNames.map(value => (
                  <option key={value}>{value}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex mt-7 gap-x-8 max-w-fit justify-between">
            <div className="mb-4 pl-5">
              <label
                htmlFor="sensortype"
                className="block  font-bold text-xl"
              >
                Sensor Type:
              </label>
              <select
                id="sensortype2"
                onChange={handleSensorTypeChange2}
                name="sensortype2"
                value={sensortype2}
                className="border mt-2 border-gray-300 p-1 w-full rounded overflow-hidden shadow-md outline-none"
              >
                <option value="" disabled>
                  Select Sensor Type
                </option>
                <option value="Accelerometer">Accelerometer</option>
                <option value="Strain Gauge">Strain Gauge</option>
                <option value="Deflection Gauge">Deflection Gauge</option>
                <option value="Camera">Camera</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="sensor-dropdown"
                className="block font-bold text-xl"
              >
                Select Sensor:
              </label>
              <select
                id="sensor-dropdown"
                value={selectedSensor}
                onChange={handleSensorChangecombine}
                className={`border mt-2 border-gray-300 p-1 w-full rounded overflow-hidden shadow-md outline-none ${!isDataAvailable
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-white"
                  }`}
                disabled={!isDataAvailable}
              >
                <option value="" disabled>
                  Select Sensor
                </option>
                {/* {console.log(sensorTypeOptions)} */}
                {sensorTypeOptions.map((sensor, index) => (
                  <option key={index} value={sensor.id}>
                    {sensor}
                  </option>
                ))}
              </select>


            </div>

            <div>
              <h5 className="font-bold text-xl "> Sensor Location</h5>
              <input
                type="text"
                value={sensorLocation2}
                onChange={(e) => setsensorLocation2(e.target.value)}
                className="p-2 border border-gray-300 rounded-md mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Sensor location will appear here"
                disabled={false}
              />
            </div>
          </div>
        </div>


        {showBridgeDetailsNew && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-3/4 max-w-4xl h-auto max-h-[90vh] overflow-y-auto p-10 rounded-lg shadow-2xl relative">
              {/* Close Button */}
              <button onClick={() => setShowBridgeDetails(false)}
                className="absolute top-4 right-4 text-2xl text-gray-600 hover:text-gray-900 focus:outline-none"
                aria-label="Close">  ✕ </button>

              {/* Header */}
              <h1 className="text-center text-4xl font-bold text-gray-800 mb-8">  Bridge Information</h1>

              {/* Form */}
              <form className="space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {/* Left Column */}
                  <div>
                    <div className="mb-6">
                      <label htmlFor="country" className="block text-gray-700 font-semibold mb-2"> Select Country: </label>
                      <select id="country" value={userData.country} onChange={handleCountryChange}
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none" >
                        <option value="USA">USA</option>
                        <option value="India">India</option>
                        <option value="India">Australia</option>
                        <option value="India">Canada</option>
                      </select>
                    </div>
                    <div className="mb-6">
                      <label htmlFor="division" className="block text-gray-700 font-semibold mb-2"> Division:</label>
                      <input type="text" id="division" placeholder="Enter Division"
                        value={userData.division}
                        onChange={(e) =>
                          setUserData((prevData) => ({ ...prevData, division: e.target.value }))
                        }
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                      />
                    </div>
                    <div className="mb-6">
                      <label htmlFor="name" className="block text-gray-700 font-semibold mb-2"> Bridge Name: </label>
                      <input type="text" id="name" placeholder="Enter Bridge Name"
                        value={userData.bridgeName}
                        onChange={(e) =>
                          setUserData((prevData) => ({ ...prevData, bridgeName: e.target.value }))
                        }
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                      />
                    </div>
                  </div>

                  {/* Middle Column */}
                  <div>
                    <div className="mb-6">
                      <label htmlFor="state" className="block text-gray-700 font-semibold mb-2">    Select State:  </label>
                      <select id="state" value={userData.state} onChange={handleStateChange}
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none">
                        <option value="">{userData.state}</option>
                        {statesList.map((stateName) => (
                          <option key={stateName} value={stateName}>
                            {stateName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-6">
                      <label htmlFor="location" className="block text-gray-700 font-semibold mb-2"> Zip Code:</label>
                      <input type="text" id="location" placeholder="Enter Zip Code" value={userData.zipCode} onChange={(e) =>
                        setUserData((prevData) => ({ ...prevData, zipCode: e.target.value }))
                      }
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                      />
                    </div>
                  </div>

                  {/* Right Column */}
                  <div>
                    <div className="mb-6">
                      <label htmlFor="city" className="block text-gray-700 font-semibold mb-2">  City: </label>
                      <input type="text" id="city" placeholder="Enter City / Area"
                        value={userData.city} onChange={(e) =>
                          setUserData((prevData) => ({ ...prevData, city: e.target.value }))
                        }
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                      />
                    </div>
                    <div className="mb-6">
                      <label htmlFor="coordinates" className="block text-gray-700 font-semibold mb-2"> Bridge Coordinates: </label>
                      <input type="text" id="coordinates" placeholder="Enter Coordinates"
                        value={userData.coordinates} onChange={(e) =>
                          setUserData((prevData) => ({ ...prevData, coordinates: e.target.value }))
                        }
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Save Button */}
                <div className="text-center">
                  <button type="button" onClick={updateData}
                    className="w-full py-3 px-6 bg-pink-600 text-white text-lg font-semibold rounded-md hover:bg-pink-800 focus:outline-none focus:ring-4 focus:ring-pink-300"
                  > Save</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>


      <div className="w-auto mr-8 p-6 flex flex-wrap justify-center items-center bg-white">
        {/* Accelerometer and Tiltmeter in one row */}
        <div
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
            width: "102%",
          }}
        >
          {/* Accelerometer */}
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginBottom: "20px",
              width: "50%",
            }}
          >
            {/* Accelerometer Section */}
            <div
              style={{
                border: "2px solid black",
                padding: "20px",
                borderRadius: "10px",
                height: "555px",
                width: "100%",
              }}
            >
              {/* Header with Title and Dropdowns */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <h1 className="text-xl">Accelerometer</h1>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <h2
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  ></h2>

                  <select
                    onChange={(e) => handleDownload(e.target.value)}
                    className="border border-black rounded-lg p-1  shadow-sm w-[100px]"
                    style={{ width: "110px" }}
                  >
                    <option value="">Download</option>
                    <option value="excel">Download Excel</option>
                    <option value="pdf">Download PDF</option>
                    <option value="word">Download Word</option>
                  </select>
                  <select
                    onChange={(e) =>
                      handleDataSourceChange(e.target.value)
                    }
                    className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                    style={{ width: "120px" }}
                  >
                    <option value="api">Fetch Data from API</option>
                    <option value="csv">Upload CSV</option>
                  </select>
                  {dataSource === "csv" && (
                    <input
                      type="file"
                      accept=".csv"
                      onChange={(e) =>
                        handleCSVUpload(e.target.files[0])
                      }
                      className="border border-black rounded-lg p-2 shadow-sm"
                      style={{ width: "150px" }}
                    />
                  )}
                </div>
              </div>

              {/* Controls for Axis and Date Range */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginBottom: "20px",
                }}
              >
                {/* Select Axis */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label
                    style={{ fontWeight: "bold", fontSize: "13px" }}
                  >
                    Select Axis:{" "}
                  </label>
                  <select
                    value={selectedAxis}
                    onChange={(e) => setSelectedAxis(e.target.value)}
                    className="yellow-dropdown"
                    style={{ border: "2px solid black" }}
                  >
                    <option value="all">All three Axis</option>
                    <option value="x">X-Axis</option>
                    <option value="y">Y-Axis</option>
                    <option value="z">Z-Axis</option>
                  </select>
                </div>

                {/* Select Start Date */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label
                    style={{ fontWeight: "bold", fontSize: "13px" }}
                  >
                    Select Start Date:{" "}
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MM-dd-yy"
                    maxDate={new Date()}
                    minDate={new Date().setMonth(
                      new Date().getMonth() - 3
                    )}
                    className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                  />
                </div>

                {/* Select End Date */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label
                    style={{ fontWeight: "bold", fontSize: "13px" }}
                  >
                    Select End Date:{" "}
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="MM-dd-yy"
                    maxDate={new Date()}
                    minDate={startDate}
                    className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                  />
                </div>
              </div>
              {/* Chart */}

              <div>
                <div
                  style={{
                    width: "100%",
                    height: "360px",
                    // overflow: "scroll",
                  }}
                >
                  <Line
                    ref={chartRef}
                    data={liveChartData}
                    options={chartOptions}
                  />
                  <h2 className="text-center font-semibold ">
                    * Note : Accelerometer data is currently unavailable<br />
                    * Note : Horizontal scroll WIP
                  </h2>
                </div>
              </div>
            </div>

            {/* Download Data Section */}
          </div>

          {/* Tiltmeter */}
          <div
            style={{
              border: "2px solid black",
              padding: "20px",
              borderRadius: "10px",
              width: "50%",
              height: "555px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <h1 className="text-2xl">Tiltmeter</h1>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Download Data
                </h2>
                <select
                  onChange={(e) => handleDownloadTilt(e.target.value)}
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                  style={{ width: "100px" }}
                >
                  <option value="">Select Format</option>
                  <option value="excel">Download Excel</option>
                  <option value="pdf">Download PDF</option>
                  <option value="word">Download Word</option>
                </select>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Select Axis:{" "}
                </label>
                <select
                  value={selectedTiltAxis}
                  onChange={(e) =>
                    setSelectedTiltAxis(e.target.value)
                  }
                  className="yellow-dropdown"
                  style={{ border: "2px solid black" }}
                >
                  <option value="all">All three Axis</option>
                  <option value="x">X-Axis</option>
                  <option value="y">Y-Axis</option>
                  <option value="z">Z-Axis</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Select Start Date:{" "}
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={new Date().setMonth(
                    new Date().getMonth() - 3
                  )}
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Select End Date:{" "}
                </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={startDate}
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                />
              </div>
            </div>

            <div
              style={{
                height: "600px",
                width: "100%",
                overflow: "auto",
              }}
            >
              <Line data={tiltGraphData} options={options} />
              <h2 className="text-center font-semibold">
                * Note : Tiltmeter data pull WIP
              </h2>
            </div>
          </div>
        </div>

        {/* Temperature and Pressure in one row */}
        <div
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "40px",
            width: "102%",
          }}
        >
          {/* Temperature Monitoring */}
          <div
            style={{
              border: "2px solid black",
              padding: "30px",
              borderRadius: "10px",
              height: "555px",
              width: "50%",
            }}
          >
            {/* Title */}
            <h1 className="text-2xl">Temperature</h1>
            <br></br>

            {/* Controls for Start Date, End Date, and Download Option */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginBottom: "20px",
              }}
            >
              {/* Start Date */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Start Date:{" "}
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={new Date().setMonth(
                    new Date().getMonth() - 3
                  )} // Limit date range to 3 months
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                />
              </div>

              {/* End Date */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  End Date:{" "}
                </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={startDate} // Ensure end date is after start date
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                />
              </div>

              {/* Download Option */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Download Data
                </h2>
                <select
                  onChange={(e) =>
                    handleDownloadTemperature(e.target.value)
                  }
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                  style={{ width: "100px" }}
                >
                  <option value="">Select Format</option>
                  <option value="excel">Download Excel</option>
                  <option value="pdf">Download PDF</option>
                  <option value="word">Download Word</option>
                </select>
              </div>
            </div>

            {/* Temperature Chart */}
            {temperatureData.length > 0 ? (
              <div style={{ height: "400px", width: "500px" }}>
                <Line
                  data={temperatureChartData}
                  options={temperatureChartOptions}
                />
              </div>
            ) : (
              <p>Loading data...</p>
            )}
          </div>

          {/* Pressure Monitoring */}
          <div
            style={{
              border: "2px solid black",
              padding: "20px",
              borderRadius: "10px",
              width: "50%",
              height: "555px",
            }}
          >
            {/* Header with Download Option */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <h1 className="text-2xl">Pressure</h1>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Download Data
                </h2>
                <select
                  onChange={(e) =>
                    handleDownloadPressure(e.target.value)
                  }
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                  style={{ width: "100px" }}
                >
                  <option value="">Select Format</option>
                  <option value="excel">Download Excel</option>
                  <option value="pdf">Download PDF</option>
                  <option value="word">Download Word</option>
                </select>
              </div>
            </div>

            {/* Axis Selection and Date Picker */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginBottom: "15px",
              }}
            >
              {/* Axis Selector */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Select Axis:{" "}
                </label>
                <select
                  value={selectedPressureAxis}
                  onChange={(e) =>
                    setSelectedPressureAxis(e.target.value)
                  }
                  className="yellow-dropdown w-[100px]"
                  style={{ border: "2px solid black" }}
                >
                  <option value="x">X-Axis</option>
                  <option value="y">Y-Axis</option>
                  <option value="z">Z-Axis</option>
                </select>
              </div>

              {/* Start Date Picker */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Select Start Date:{" "}
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={new Date().setMonth(
                    new Date().getMonth() - 3
                  )} // Limit to last 3 months
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                />
              </div>

              {/* End Date Picker */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Select End Date:{" "}
                </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={startDate}
                  className="border border-black rounded-lg p-1 shadow-sm w-[100px]"
                />
              </div>
            </div>

            {/* Pressure Chart */}
            {pressureData.length > 0 ? (
              <div style={{ height: "400px", width: "500px" }}>
                <Line
                  data={pressureChartData}
                  options={pressureChartOptions}
                />
              </div>
            ) : (
              <p>Loading data...</p>
            )}
          </div>
        </div>
        {/* Humidity and Battery Gauge in one row */}
        <div
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
            width: "102%",
            marginLeft: "-1em",
          }}
        >
          {/* Humidity Monitoring */}
          <div
            style={{
              border: "2px solid black",
              padding: "30px",
              borderRadius: "10px",
              height: "555px",
              width: "50%",
            }}
          >
            {/* Title */}
            <h1 className="text-2xl">Humidity</h1>

            {/* Controls for Start Date, End Date, and Download Option */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginBottom: "20px",
              }}
            >
              {/* Start Date */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  Start Date:{" "}
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={new Date().setMonth(
                    new Date().getMonth() - 3
                  )} // Limit date range to 3 months
                  className="border border-black rounded-lg p-1 w-[100px] shadow-sm"
                />
              </div>

              {/* End Date */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                >
                  End Date:{" "}
                </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM-dd-yy"
                  maxDate={new Date()}
                  minDate={startDate} // Ensure end date is after start date
                  className="border border-black rounded-lg p-1 w-[100px] shadow-sm"
                />
              </div>

              {/* Download Option */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Download Data
                </h2>
                <select
                  onChange={(e) => handleDownload(e.target.value)}
                  className="border border-black rounded-lg p-1 w-[100px] shadow-sm"
                  style={{ width: "100px" }}
                >
                  <option value="">Select Format</option>
                  <option value="excel">Download Excel</option>
                  <option value="pdf">Download PDF</option>
                  <option value="word">Download Word</option>
                </select>
              </div>
            </div>

            {/* Humidity Chart */}
            {humidityData.length > 0 ? (
              <div style={{ height: "400px", width: "500px" }}>
                <Line
                  data={humidityGraphData}
                  options={humidityOptions}
                />
                <h2 className="text-center font-semibold">
                  {" "}
                  * Note : Humidity data pull WIP
                </h2>
              </div>
            ) : (
              <p>Loading data...</p>
            )}
          </div>
          <div className="w-full ">
            <div className="flex items-center justify-center">
              {/* Battery Gauge */}
              <BatteryGauge className="align-middle" />
            </div>
          </div>
        </div>
          <div className="bg-indigo-800 w-full text-center py-6 overflow-hidden shadow-xl rounded-xl mt-6 text-white h-auto">
            {typeof Weather.main != "undefined" ? (
              <div className="flex justify-center align-center w-full">
                <div className="w-1/4">
                  <div className="flex justify-start">
                    {Weather.weather[0].main === "Clouds" && (
                      <img
                        className="w-24"
                        src={cloud_icon}
                        alt="Cloud Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Smoke" && (
                      <img
                        className="w-24"
                        src={mist_icon}
                        alt="Mist Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Haze" && (
                      <img
                        className="w-24"
                        src={mist_icon}
                        alt="Mist Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Clear" && (
                      <img
                        className="w-24"
                        src={clear_icon}
                        alt="Clear Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Rain" && (
                      <img
                        className="w-24"
                        src={rain_icon}
                        alt="Rain Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Mist" && (
                      <img
                        className="w-24"
                        src={mist_icon}
                        alt="Mist Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Snow" && (
                      <img
                        className="w-24"
                        src={snow_icon}
                        alt="Snow Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Thunderstorm" && (
                      <img
                        className="w-24"
                        src={thunderstorm_icon}
                        alt="Thunderstorm Icon"
                      />
                    )}
                    {Weather.weather[0].main === "Drizzle" && (
                      <img
                        className="w-24"
                        src={drizzle_icon}
                        alt="Drizzle Icon"
                      />
                    )}
                  </div>
                  <div>
                    <p className="text-3xl mt-4 text-left">
                      {Weather.weather[0].main}
                    </p>
                  </div>
                </div>

                <div className="w-1/3 text-left">
                  <p className="text-4xl font-semibold">
                    {Weather.name}, {Weather.sys.country}
                  </p>
                  <br />
                  <p className="text-4xl font-semibold">
                    {Weather.main.temp}°C
                  </p>
                  <p className="text-xl">
                    ({Weather.weather[0].description})
                  </p>
                </div>

                <div className="w-1/3">
                  <div className="flex">
                    <WiHumidity size={25} />
                    <p className="ml-2 text-sm">
                      Air Humidity: {Weather.main.humidity}%
                    </p>
                  </div>
                  <div className=" mt-2 flex">
                    <PiWind size={25} />
                    <p className="ml-2 text-sm">
                      Wind Speed: {Weather.wind.speed} km/h
                    </p>
                  </div>
                  <div className="mt-2 flex">
                    <GiSpeedometer size={20} />
                    <p className="ml-2 text-sm">
                      Air Pressure: {Weather.main.pressure} mBar
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                No weather report could be found for {userData.city}
                . <br />
                Edit the city name to check if the area's weather
                gets shown. <br /> Source:
                https://openweathermap.org/
              </div>
            )}
          </div>
      </div>

    </>
  );
};

export default Home;