import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './tailwind.css';
import PhoneInput from 'react-phone-input-2';
import { FaTrash } from "react-icons/fa6";

import { MdSearch } from 'react-icons/md';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoArrowBackCircleSharp } from "react-icons/io5";

import logo from '../Assets/logo2.png';
import loadingIcon from '../Assets/loading.gif';

const Home = () => {
  const navigate = useNavigate();
  const [showBridge, setshowBridge] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { ID } = useParams();
  const dataa = useParams();
  console.log(dataa)
  console.log(ID)

  const showAddBridge = (e) => {
    e.preventDefault();
    setshowBridge(!showBridge);
    setIsExpanded(!isExpanded);
  };

  const addbridge = () => {
    navigate('./bridgeform');
  };

  const [showexcelfile, setshowexcelfile] = useState(false);

  const addcsv = async (e) => {
    e.preventDefault();
    setshowexcelfile(!showexcelfile);
    setshowBridge(false);
  };

  const [searchKeyword, setSearchKeyword] = useState('');



  const [superdata, setsuperdata] = useState([]);
  const superadminId = localStorage.getItem('superadminId')
  console.log('superadminId:', superadminId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const email = localStorage.getItem('email');
        // if(!email){
        //     navigate('/');
        //     enqueueSnackbar('Please Login to Navigate!', { variant: 'error'});
        //     return;
        // }
        // if (!superadminId) {
        //   enqueueSnackbar('Selected user is not a Superadmin!', { variant: 'error' });
        //   navigate('/');
        // }

        const response = await axios.get(`https://shmbackend.dext.site/bridge/superbridges?superadminId=${superadminId}`);
        if (response.status >= 200 && response.status < 300) {
          console.log("superadmin bridge details", response.data);
          if (Array.isArray(response.data)) {
            setsuperdata(response.data);
          } else {
            setsuperdata([response.data]);
          }
        } else {
          console.error('Failed to fetch data:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, [superadminId, navigate, enqueueSnackbar]);

  const RedirectDashboard = (bridgeName, ID) => {
    // localStorage.setItem('bridgeName', bridgeName);
    navigate(`/home/dashboard/bridge/${ID}`);
  };

  const filteredData = superdata.filter((bridge) =>
    bridge.bridgeName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    bridge.country.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    bridge.state.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    bridge.division.toLowerCase().includes(searchKeyword.toLowerCase())
  );


  const handleRowClick = (bridge) => {
    if (bridge.id && bridge.bridgeName) {
      RedirectDashboard(bridge.bridgeName, bridge?.id);
      enqueueSnackbar('Welcome to Dashboard!', { variant: 'success' });
    }
  };

  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData(parsedData);
      setSelectedFile({ file, data: parsedData });
    };
  };

 

  const postDataToServer = async () => {

     const AdminId = localStorage.getItem('superadminId');
  const AdminName = localStorage.getItem('name');
    if (!selectedFile) {
      enqueueSnackbar('Please add a file!', { variant: 'error' });
    }
    else {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', selectedFile.file);
        formData.append('superAdminName', AdminName);
      
        formData.append('superAdminId', AdminId);
        console.log(AdminId);
        console.log(AdminName);
        const response = await axios.post('https://shmbackend.dext.site/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          
          console.log('Backend Response: ', response.data);
          enqueueSnackbar('File successfully uploaded!', { variant: 'success' });
          navigate('/home');
          setshowexcelfile(prev=>!prev);
          const updatedResponse = await axios.get(`https://shmbackend.dext.site/bridge/superbridges?superadminId=${superadminId}`);
          setsuperdata(updatedResponse.data);
        }

      }
      catch (error) {
        setLoading(false);
        console.log(error);
        enqueueSnackbar('Unable to submit form, please check your file format!', { variant: 'error' });
      }

    }
  };

  const back = (e) => {
    setshowexcelfile(false);
    setshowBridge(false);
    setIsExpanded(!isExpanded);
  };

  const sample_csv = 'https://shm.dext.site/sample.xlsx'

  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };




  const [bridgename, setbridgename] = useState('');
  const [id, setId] = useState('');
  const [showBridgeDetails, setShowBridgeDetails] = useState(false);


  const Modify = (bn, id) => {
    setbridgename(bn);
    setId(id);
    console.log(bridgename);
    setShowBridgeDetails(true);

  };


  // useEffect(() => {
  //   const findBridgeID = async () => {
  //       try {
  //           const response = await axios.get(`https://shmbackend.dext.site/bridge/bridgeid?bridgeName=${bridgename}`)
  //           if (response.status >= 200 && response.status < 300) {
  //               console.log(response.data)
  //               setId(response.data)
  //           } else {
  //               console.error('Failed to fetch data:', response.statusText);
  //           }
  //       } catch (error) {
  //           console.error('Error:', error);
  //       }

  //   };

  //   findBridgeID();
  // }, [bridgename]);

  const [statesList, setStatesList] = useState([]);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setUserData(prevData => ({ ...prevData, country: selectedCountry }));

    // Fetch states based on selected country
    if (selectedCountry === 'USA') {
      setStatesList(['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']);
    } else if (selectedCountry === 'India') {
      setStatesList(['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal']);
    } else {
      setStatesList([]);
    }

    // Reset state selection
    setUserData(prevData => ({ ...prevData, state: '' }));
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setUserData(prevData => ({ ...prevData, state: selectedState }));
  };

  const initialUserData = {
    country: '',
    state: '',
    city: '',
    coordinates: '',
    division: '',
    location: '',
    bridgeName: '',
    adminInfo: {
      adminEmail: '',
      adminName: '',
      adminPhone: '',
      adminEmail2: '',
      adminName2: '',
      adminPhone2: '',
      adminEmail3: '',
      adminName3: '',
      adminPhone3: '',
      admin1countryCode: 'code',
      admin2countryCode: 'code',
      admin3countryCode: 'code',
    },
    managerInfo: {
      managerEmail: '',
      managerName: '',
      managerPhone: '',
      managerEmail2: '',
      managerName2: '',
      managerPhone2: '',
      managerEmail3: '',
      managerName3: '',
      managerPhone3: '',
      managerEmail4: '',
      managerName4: '',
      managerPhone4: '',
      managerEmail5: '',
      managerName5: '',
      managerPhone5: '',
      managerEmail6: '',
      managerName6: '',
      managerPhone6: '',
      manager1countryCode: 'code',
      manager2countryCode: 'code',
      manager3countryCode: 'code',
      manager4countryCode: 'code',
      manager5countryCode: 'code',
      manager6countryCode: 'code',
    },
    ownerInfo: {
      ownerEmail: '',
      ownerName: '',
      ownerPhone: '',
      ownerEmail2: '',
      ownerName2: '',
      ownerPhone2: '',
      ownerEmail3: '',
      ownerName3: '',
      ownerPhone3: '',
      owner1countryCode: 'code',
      owner2countryCode: 'code',
      owner3countryCode: 'code',
    },
    noofgirders: '',
    nobridgespan: ''
  };

  const [userData, setUserData] = useState(initialUserData)

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Hello Void')
        if (!id) {
          return;
        }
        const response = await axios.get(`https://shmbackend.dext.site/bridge/getbridge/${id}`);
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data);
          const { country, state, city, coordinates, division, zipCode, bridgeName,
            adminName, adminEmail, adminPhone, adminName2, adminEmail2, adminPhone2,
            adminName3, adminEmail3, adminPhone3, managerName, managerEmail, managerPhone,
            managerName2, managerEmail2, managerPhone2, managerName3, managerEmail3, managerPhone3,
            managerName4, managerEmail4, managerPhone4, managerName5, managerEmail5, managerPhone5,
            managerName6, managerEmail6, managerPhone6, ownerName, ownerEmail, ownerPhone, ownerName2,
            ownerEmail2, ownerPhone2, ownerName3, ownerEmail3, ownerPhone3, nobridgespan, noofgirders,
            admin1countryCode, admin2countryCode, admin3countryCode, owner1countryCode, owner2countryCode,
            owner3countryCode, manager1countryCode, manager2countryCode, manager3countryCode, manager4countryCode,
            manager5countryCode, manager6countryCode } = response.data;

          console.log(response)

          setUserData({
            country, state, city, coordinates, division, zipCode, bridgeName,
            adminName, adminEmail, adminPhone, adminName2, adminEmail2, adminPhone2,
            adminName3, adminEmail3, adminPhone3, managerName, managerEmail, managerPhone,
            managerName2, managerEmail2, managerPhone2, managerName3, managerEmail3, managerPhone3,
            managerName4, managerEmail4, managerPhone4, managerName5, managerEmail5, managerPhone5,
            managerName6, managerEmail6, managerPhone6, ownerName, ownerEmail, ownerPhone, ownerName2,
            ownerEmail2, ownerPhone2, ownerName3, ownerEmail3, ownerPhone3, nobridgespan, noofgirders,
            admin1countryCode, admin2countryCode, admin3countryCode, owner1countryCode, owner2countryCode,
            owner3countryCode, manager1countryCode, manager2countryCode, manager3countryCode, manager4countryCode,
            manager5countryCode, manager6countryCode
          });
        } else {
          console.error('Failed to fetch data:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id]);

  console.log(userData)



  const updateData = async (dataToUpdate) => {
    if (!userData.adminName || !userData.adminEmail || !userData.adminPhone || userData.admin1countryCode === 'code') {
      enqueueSnackbar('Please Add Atleast One Admin!', { variant: 'error' });
    }
    else if (!userData.managerName || !userData.managerEmail || !userData.managerPhone || userData.manager1countryCode === 'code') {
      enqueueSnackbar('Please Add Atleast One Manager!', { variant: 'error' });
    }
    else if (!userData.ownerName || !userData.ownerEmail || !userData.ownerPhone || userData.owner1countryCode === 'code') {
      enqueueSnackbar('Please Add Atleast One Owner!', { variant: 'error' });
    }
    else {
      try {
        const dataToUpdate = {
          country: userData.country,
          state: userData.state,
          city: userData.city,
          coordinates: userData.coordinates,
          division: userData.division,
          zipCode: userData.zipCode,
          bridgeName: userData.bridgeName,

          adminEmail: userData.adminEmail,
          adminName: userData.adminName,
          adminPhone: userData.adminPhone,
          managerEmail: userData.managerEmail,
          managerName: userData.managerName,
          managerPhone: userData.managerPhone,
          ownerEmail: userData.ownerEmail,
          ownerName: userData.ownerName,
          ownerPhone: userData.ownerPhone,

          adminEmail2: userData.adminEmail2,
          adminName2: userData.adminName2,
          adminPhone2: userData.adminPhone2,
          managerEmail2: userData.managerEmail2,
          managerName2: userData.managerName2,
          managerPhone2: userData.managerPhone2,
          ownerEmail2: userData.ownerEmail2,
          ownerName2: userData.ownerName2,
          ownerPhone2: userData.ownerPhone2,

          adminEmail3: userData.adminEmail3,
          adminName3: userData.adminName3,
          adminPhone3: userData.adminPhone3,
          managerEmail3: userData.managerEmail3,
          managerName3: userData.managerName3,
          managerPhone3: userData.managerPhone3,
          ownerEmail3: userData.ownerEmail3,
          ownerName3: userData.ownerName3,
          ownerPhone3: userData.ownerPhone3,

          managerEmail4: userData.managerEmail4,
          managerName4: userData.managerName4,
          managerPhone4: userData.managerPhone4,

          managerEmail5: userData.managerEmail5,
          managerName5: userData.managerName5,
          managerPhone5: userData.managerPhone5,

          managerEmail6: userData.managerEmail6,
          managerName6: userData.managerName6,
          managerPhone6: userData.managerPhone6,

          admin1countryCode: userData.admin1countryCode,
          admin2countryCode: userData.admin2countryCode,
          admin3countryCode: userData.admin3countryCode,

          owner1countryCode: userData.owner1countryCode,
          owner2countryCode: userData.owner2countryCode,
          owner3countryCode: userData.owner3countryCode,

          manager1countryCode: userData.manager1countryCode,
          manager2countryCode: userData.manager2countryCode,
          manager3countryCode: userData.manager3countryCode,
          manager4countryCode: userData.manager4countryCode,
          manager5countryCode: userData.manager5countryCode,
          manager6countryCode: userData.manager6countryCode,
        };
        const response = await axios.put(`https://shmbackend.dext.site/bridge/updatebridge/${id}`, dataToUpdate);
        if (response.status >= 200 && response.status < 300) {
          enqueueSnackbar('Data Updated Successfully', { variant: 'success' });
          console.log('Data updated successfully:', response.data);
          setShowBridgeDetails(false);
          const updatedResponse = await axios.get(`https://shmbackend.dext.site/bridge/superbridges?superadminId=${superadminId}`);

          setsuperdata(updatedResponse.data);
          return response.data;
        }
      }
      catch (error) {
        console.error('Error updating data:', error);
        throw error;
      }
    }
  };


  //SensorData
  const [sensorDataList, setSensorDataList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [bridgeIdToDelete, setBridgeIdToDelete] = useState(null);


  const [sensorData, setsensorData] = useState({
    sensortype: '',
    spanno: '',
    girderno: '',
    manualLocation: '',
  });

  const handleDeleteClick = async (bridgename1) => {
    try {
      // Fetch the bridge ID based on the name
      const response = await axios.get(`https://shmbackend.dext.site/bridge/bridgeid?bridgeName=${bridgename1}`);

      if (response.status >= 200 && response.status < 300) {
        const ID = response.data;
        // Show the confirmation dialog
        setShowDialog(true);

        // Store the bridge id for later use in handleConfirm
        setBridgeIdToDelete(ID);
      } else {
        console.error('Failed to fetch bridge ID:', response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);

      enqueueSnackbar("An error occurred while fetching the bridge ID.", { variant: 'error' });

    }
  };

  const handleConfirm = async () => {
    try {
      // Proceed with deletion after confirmation
      const deleteResponse = await fetch(`https://shmbackend.dext.site/bridge/deletebridge/${bridgeIdToDelete}`, {
        method: 'DELETE',
      });

      if (!deleteResponse.ok) {
        const error = await deleteResponse.json();
        enqueueSnackbar(`Failed to delete bridge: ${error.message}`, { variant: 'error' });
      } else {
        enqueueSnackbar('Bridge Deleted Successfully', { variant: 'success' });
        // Optionally, you can refresh the list or update the state here
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting bridge:", error);
      alert();
      enqueueSnackbar("An error occurred while deleting the bridge.", { variant: 'error' });
    } finally {
      // Close the dialog after action
      setShowDialog(false);
    }
  };

  const handleCancel = () => {
    // Just close the dialog without doing anything
    setShowDialog(false);
  };






  return (
    <>
      <button onClick={showAddBridge} className='justify-center absolute mb-16 py-4 z-40 font-semibold text-2xl w-full bg-indigo-600 hover:bg-indigo-900 flex cursor-pointer overflow-hidden shadow-md text-white'>Click here to add a new bridge &nbsp; {isExpanded ? <FaChevronUp size={30} style={{ marginTop: '2px' }} /> : <FaChevronDown size={30} style={{ marginTop: '2px' }} />}</button>
      {showBridge && (
        <div className="absolute w-full mt-16 pb-24 pt-20 bg-gray-200 shadow-2xl text-center border border-gray-300 rounded-sm">
          <div className="w-full flex justify-center">
            <img src={logo} alt="" />
          </div>
          <br /><br /><br />
          <div className="w-full">
            <h1 className="text-3xl font-bold text-black mb-8">Welcome, <br /><br />Choose a method to Register a new Bridge</h1>
          </div>
          <br /><br />
          <div className="w-full justify-center">
            <button onClick={addbridge} className="bg-blue-600 w-1/3 py-4 text-2xl font-semibold text-gray-100 rounded-lg overflow-hidden shadow-xl hover:bg-blue-900">Add Manually</button>
            <br /><br /><br />
            <button onClick={addcsv} className="bg-blue-600 py-4 w-1/3 text-2xl font-semibold text-gray-100 rounded-lg overflow-hidden shadow-xl hover:bg-blue-900">Upload Excel</button>
          </div>
        </div>
      )}

      {showexcelfile && (
        <div className='absolute bg-gray-100 w-full text-center z-50'>
          <button className='flex justify-start pl-6 mt-4 underline' onClick={back}><IoArrowBackCircleSharp size={28} />Back</button>
          <div className="flex justify-center mb-6">
            <img src={logo} alt="" />
          </div><br />
          <div>
            <h1 className='text-3xl font-semibold py-6'>Sample File</h1><br />
            <p className='text-gray-800'>Download this sample excel file for additional reference. <br />Create your own excel file by referring to the format of the sample file.</p><br />
            <button className="cursor-pointer bg-pink-600 text-white p-2 mt-6 rounded-sm hover:bg-pink-900" onClick={() => { downloadFileAtURL(sample_csv) }}>Sample Download</button>
          </div>
          <br /><br /><hr /><br />
          <h1 className='text-3xl font-semibold py-6'>Select File</h1><br />
          <p className='text-gray-800'>Choose your excel file with reference to our sample excel file and upload it. <br />Make sure that the format matches that of our provided sample file.</p>
          <br />
          <div className="flex pt-6 pb-10 w-full justify-center">
            <input id='fileinput' className='hidden' type="file" accept='.xlsx , .xls , .csv' onChange={handleFileUpload} />
            <label htmlFor="fileinput" className="cursor-pointer bg-blue-600 text-white p-2 px-8 rounded-sm hover:bg-blue-900" >{selectedFile ? `${selectedFile.file.name}` : 'Choose File'}</label>
            {loading ? (
              <img id='homeload' src={loadingIcon} alt="Loading" />
            ) : (
              <button className='bg-green-600 justify-end text-white p-2 px-4 rounded-sm ml-12 hover:bg-green-900' onClick={postDataToServer}>Submit</button>
            )}
          </div>
        </div>
      )}



      {showBridgeDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-3/4 max-w-4xl h-auto max-h-[90vh] overflow-y-auto p-10 rounded-lg shadow-2xl relative">
            {/* Close Button */}
            <button onClick={() => setShowBridgeDetails(false)}
              className="absolute top-4 right-4 text-2xl text-gray-600 hover:text-gray-900 focus:outline-none"
              aria-label="Close">  ✕ </button>

            {/* Header */}
            <h1 className="text-center text-4xl font-bold text-gray-800 mb-8">  Bridge Information</h1>

            {/* Form */}
            <form className="space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Left Column */}
                <div>
                  <div className="mb-6">
                    <label htmlFor="country" className="block text-gray-700 font-semibold mb-2"> Select Country: </label>
                    <select id="country" value={userData.country} onChange={handleCountryChange}
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none" >
                      <option value="USA">USA</option>
                      <option value="India">India</option>
                      <option value="India">Australia</option>
                      <option value="India">Canada</option>
                    </select>
                  </div>
                  <div className="mb-6">
                    <label htmlFor="division" className="block text-gray-700 font-semibold mb-2"> Division:</label>
                    <input type="text" id="division" placeholder="Enter Division"
                      value={userData.division}
                      onChange={(e) =>
                        setUserData((prevData) => ({ ...prevData, division: e.target.value }))
                      }
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                    />
                  </div>
                  <div className="mb-6">
                    <label htmlFor="name" className="block text-gray-700 font-semibold mb-2"> Bridge Name: </label>
                    <input type="text" id="name" placeholder="Enter Bridge Name"
                      value={userData.bridgeName}
                      onChange={(e) =>
                        setUserData((prevData) => ({ ...prevData, bridgeName: e.target.value }))
                      }
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                    />
                  </div>
                </div>

                {/* Middle Column */}
                <div>
                  <div className="mb-6">
                    <label htmlFor="state" className="block text-gray-700 font-semibold mb-2">    Select State:  </label>
                    <select id="state" value={userData.state} onChange={handleStateChange}
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none">
                      <option value="">{userData.state}</option>
                      {statesList.map((stateName) => (
                        <option key={stateName} value={stateName}>
                          {stateName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-6">
                    <label htmlFor="location" className="block text-gray-700 font-semibold mb-2"> Zip Code:</label>
                    <input type="text" id="location" placeholder="Enter Zip Code" value={userData.zipCode} onChange={(e) =>
                      setUserData((prevData) => ({ ...prevData, zipCode: e.target.value }))
                    }
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                    />
                  </div>
                </div>

                {/* Right Column */}
                <div>
                  <div className="mb-6">
                    <label htmlFor="city" className="block text-gray-700 font-semibold mb-2">  City: </label>
                    <input type="text" id="city" placeholder="Enter City / Area"
                      value={userData.city} onChange={(e) =>
                        setUserData((prevData) => ({ ...prevData, city: e.target.value }))
                      }
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                    />
                  </div>
                  <div className="mb-6">
                    <label htmlFor="coordinates" className="block text-gray-700 font-semibold mb-2"> Bridge Coordinates: </label>
                    <input type="text" id="coordinates" placeholder="Enter Coordinates"
                      value={userData.coordinates} onChange={(e) =>
                        setUserData((prevData) => ({ ...prevData, coordinates: e.target.value }))
                      }
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-pink-500 focus:outline-none"
                    />
                  </div>
                </div>
              </div>

              {/* Save Button */}
              <div className="text-center">
                <button type="button" onClick={updateData}
                  className="w-full py-3 px-6 bg-pink-600 text-white text-lg font-semibold rounded-md hover:bg-pink-800 focus:outline-none focus:ring-4 focus:ring-pink-300"
                > Save</button>
              </div>
            </form>
          </div>
        </div>
      )}





      <div className="w-full pt-16">
        <div className='flex py-3 px-6 bg-gray-200 border border-gray-300 overflow-hidden shadow-xl'>
          <div className="w-full justify-left">
            <img src={logo} alt="" />
          </div>
          <div className='flex w-full justify-center'>
            <h1 className='font-bold text-3xl'>Added Bridges</h1>
          </div>
          <div className="flex w-full justify-end items-center">
            <MdSearch size={36} className='text-gray-600' />
            <input type="text" placeholder="Search" className="border border-gray-300 p-2 pl-3 mr-5 outline-0 w-1/2 rounded-lg overflow-hidden shadow-md"
              value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
          </div>
        </div>
        <br />
        <div className="mt-4 bg-gray-100 border border-gray-200 rounded-sm overflow-hidden shadow-xl mx-6">
          <table className="table-auto w-full border-collapse border">
            <thead>
              <tr>
                <th className="bg-black text-lg text-white px-4 py-2 font-bold">#</th>
                <th className="bg-black text-lg text-white px-4 py-2 font-bold">Bridge Name</th>
                <th className="bg-black text-lg text-white px-4 py-2 font-bold">Country</th>
                <th className="bg-black text-lg text-white px-4 py-2 font-bold">State</th>
                <th className="bg-black text-lg text-white px-4 py-2 font-bold">City</th>
                <th className="bg-black text-lg text-white px-4 py-2 font-bold">Division</th>
                <th className="bg-black text-lg text-white px-4 py-2 font-bold">ZIP Code</th>
                <th className="bg-black text-lg text-white py-2 px-4 font-bold">Edit</th>
                <th className="bg-black text-lg text-white py-2 px-4 font-bold">Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((bridge, index) => (
                  <tr key={index} className="hover:bg-stone-400 text-center cursor-pointer border border-gray-300">
                    <td className="px-4 py-1">{index + 1}</td>
                    <td className="px-4 py-1" onClick={() => handleRowClick(bridge)}>{bridge.bridgeName}</td>
                    <td className="px-4 py-1">{bridge.country}</td>
                    <td className="px-4 py-1">{bridge.state}</td>
                    <td className="px-4 py-1">{bridge.city}</td>
                    <td className="px-4 py-1">{bridge.division}</td>
                    <td className="px-4 py-1">{bridge.zipCode}</td>
                    <td className='px-4 py-1'><button onClick={() => Modify(bridge.bridgeName, bridge.id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >  Edit </button></td>

                    <td className="py-1 px-4 flex justify-center space-x-2">
                      <button onClick={() => handleDeleteClick(bridge.bridgeName)}
                        //onClick={handleDeleteClick(bridge.bridgeName)}
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
                      >  Delete </button>
                      {showDialog && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                          <div className="bg-white p-6 rounded shadow-lg">
                            <p className="mb-4">Do you want to delete this bridge?</p>
                            <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={handleConfirm}> Yes </button>
                            <button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={handleCancel} >Cancel </button>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="py-2 text-center text-lg hover:bg-stone-400 cursor-pointer">
                    No bridges found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {data.length > 0 && (
        <table className='hidden text-left'>
          <thead>
            <tr className='grid'>
              {Object.keys(data[0]).map((key) => (
                <th className='p-2 w-full' key={key}><label htmlFor="label">{key}:</label></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr className='grid' key={index}>
                {Object.values(row).map((value, index) => (
                  <td key={index}><input className='p-2 w-full' type="text" value={value} readOnly /></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Home;